<template>
    <div class="member-service-related">
        <!-- 图片 -->
        <div class="member-service-related-cover">
            <el-image
                :src="item.img"
                fit="scale-down"
                lazy
                class="member-service-related-cover-pic">
                <!-- 加载中样式 -->
                <template v-slot:placeholder>
                    <div class="image-slot">
                        <i class="el-icon-loading"></i>
                    </div>
                </template>
                <!-- 加载失败样式 -->
                <template v-slot:error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
        </div>
        <!-- 描述 -->
        <div class="member-service-related-describe">
            <!-- 标题 -->
            <div class="member-service-related-describe-title"
                ref="describe_title">
                {{item.name}}
            </div>
            <!-- 副标题 -->
            <div class="member-service-related-describe-subtitle"
                ref="describe_subtitle">
                {{item.sketch}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            item: {
                type: Object,
                default() {return {}}
            }
        },
        watch: {
            item: {
                handler: function(value) {
                    this.handleContent(value.name, "describe_title");
                    this.handleContent(value.sketch, "describe_title");
                }
            }
        },
        methods: {
            handleContent(content, tar) { // 
                let el = this.$refs[tar],
                    o_height = el.offsetHeight;
                for(let i = 0; i <= content.length; i++) {
                    el.innerHTML = content.substr(0, i);
                    if(o_height < el.scrollHeight) {
                        el.style.overflow = 'hidden';
                        el.innerHTML = content.substr(0, i-3) + '...';
                        break;
                    }
                }
            }
        }
    }
</script>

<style scoped>
.member-service-related{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* 封面 */
.member-service-related-cover{
    position: relative;
    width: 40%;
}
.member-service-related-cover::before{
    display: block;
    content: "";
    padding-top: 100%;
}
.member-service-related-cover-pic{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* 描述 */
.member-service-related-describe{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 10px;
}
.member-service-related-describe-title{
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    overflow: auto;
}
.member-service-related-describe-subtitle{
    width: 100%;
    height: 40px;
    color: #909399;
    overflow: auto;
}
</style>